export const localStorageLogin = (token, foto, tokenBarragovbr) => {
  localStorage.setItem("localStorage", JSON.stringify({ token, foto, tokenBarragovbr }));
};

export const localStorageLogout = () => {
  localStorage.removeItem("localStorage");
};

export const getLocalStorage = () => {
  return localStorage.getItem("localStorage");
};
