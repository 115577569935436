import axios from "axios";
import { localStorageLogout } from "../scripts/localStoreUtil";
import { getLocalStorage } from "../scripts/localStoreUtil";
import config from "../config";

const api = axios.create({
  baseURL: "",
});

api.interceptors.request.use(async (config) => {
  const storage = getLocalStorage();
  if (storage) {
    const token = JSON.parse(storage).token;
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const status = error.response.status;

    if (status === 401 || status === 403) {
      console.log("Não permitido. Redirecionando para tela inicial ...");
      localStorageLogout();
      window.location.href =
        config.govbrUrl +
        "/logout?post_logout_redirect_uri=" +
        config.govbrLogoutUrl;
    }

    return Promise.reject(error.response);
  }
);

export default api;
