import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { localStorageLogout } from "../../scripts/localStoreUtil";
import config from "../../config";
import { strTobase64 } from "../../scripts/base64Util";

export default function Header() {
  const barragovbrRef = useRef();
  const session = useSelector((state) => state.usuarioStore);

  const links = [
    {
      title: "Órgaos do Governo",
      href: "https://www.gov.br/pt-br/orgaos-do-governo",
    },
    {
      title: "Acesso à informação",
      href: "https://www.gov.br/acessoainformacao/pt-br",
    },
    { title: "Legislação", href: "http://www4.planalto.gov.br/legislacao/" },
    {
      title: "Acessibilidade",
      href: "https://www.gov.br/governodigital/pt-br/acessibilidade-digital",
    },
  ];

  useEffect(() => {
    const MAP_NIVEL_CONTA = {
      OURO: 3,
      PRATA: 2,
      BRONZE: 1,
    };

    if (!barragovbrRef?.current) {
      return;
    }
    
    if (!!session?.tokenBarragovbr) {
      const nivelConta = MAP_NIVEL_CONTA[session.usuario.selo_conta];

      barragovbrRef.current.token(session.tokenBarragovbr);
      barragovbrRef.current.nomeCompleto = session.usuario.nome || "";
      barragovbrRef.current.foto = session.photo || null;
      barragovbrRef.current.nivelConta = nivelConta || null;
      barragovbrRef.current.empresa = session.usuario.isCNPJ;
    }

    barragovbrRef.current.logado = session.token ? true : false;
  }, [session, barragovbrRef]);

  const handleLogout = () => {
    localStorageLogout();
    window.location.href =
      config.govbrUrl +
      "/logout?post_logout_redirect_uri=" +
      config.govbrLogoutUrl;
  };

  const handleAccessTokenError = () => {
    const state = strTobase64(
      JSON.stringify({ path: window.location.pathname })
    );

    const urlGovbr = config.govbrUrl;
    const servicoAutorizacaoGovbr = "/authorize";
    const response_type = "code";
    const client_id = config.govbrClientId;
    const scope =
      "openid+(email/phone)+profile+govbr_empresa+govbr_confiabilidades+govbr_wallet+govbr_notificacao";
    const redirect_uri = config.govbrRedirectUri;
    const nonce = Math.floor(Math.random() * 99999);

    const urlLoginGovbr =
      urlGovbr +
      servicoAutorizacaoGovbr +
      "?response_type=" +
      response_type +
      "&client_id=" +
      client_id +
      "&scope=" +
      scope +
      "&redirect_uri=" +
      redirect_uri +
      "&nonce=" +
      nonce +
      "&state=" +
      state;

    window.location.href = urlLoginGovbr;
  };

  useEffect(() => {
    window.addEventListener("signedOut", handleLogout);
    window.addEventListener("ACCESSTOKEN_ERROR", handleAccessTokenError);

    return () => {
      window.removeEventListener("signedOut", handleLogout);
      window.removeEventListener("ACCESSTOKEN_ERROR", handleAccessTokenError);
    };
  }, []);

  return (
    <barra-govbr
      class="no-cookie"
      ref={barragovbrRef}
      linkLogo="https://www.gov.br/pt-br"
      linksDoSistema={JSON.stringify(links, null, 2)}
      ambiente="homologacao"
    />
  );
}
